<!-- eslint-disable no-tabs -->
<template>
	<div>
		<load v-if="loader" color="#2474b2" size="70" />
		<ValidationObserver ref="form" v-else>
			<form @submit.prevent="loginStep">
				<div class="col-md-12 mt-3 mb-1">
						<label class="label_default mb-0">
							Qual seu
							<strong>CPF ou CNPJ ?</strong>
						</label>
					</div>
					<ValidationProvider
						v-slot="{ errors, ariaMsg, classes ,validate}"
						:rules="{ required: true, min:CPF.length <= 11 ? 11 : 14 }"
						id="loginInputContainer"
						name="CPF"
						tag="div"
						:bails="false"
						class="col-md-12 my-1 mb-3"
					>
						<the-mask
							type="text"
							id="inputLogin"
							class="input_default form-control"
							:mask="['###.###.###-##', '##.###.###/####-##']"
							@blur.native="validate($event)"
							v-model="CPF"
							placeholder="..."
							:class="classes"
							:masked="false"
						/>
						<div>
							<div
								type="submit"
								class="btn_default px-2 py-2"
								:disabled="disableSpinnerButtonCpf"
								id="buttonCpfLogin"
								@click="getUserWithCpf()"
							>
								<span
									class="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true"
									v-show="disableSpinnerButtonCpf"
								></span>
								{{ !disableSpinnerButtonCpf ? ">" : "" }}
              </div>
						</div>
					</ValidationProvider>
				<div class="col-md-12 mt-2 mb-0">
					<label class="label_default mb-0">
						Escolha a
						<strong>Unidade</strong>
					</label>
				</div>
				<ValidationProvider
					v-slot="{ errors, ariaMsg, classes }"
					rules="required"
					name="Unidade"
					tag="div"
					:bails="false"
					class="mb-2 mt-2"
				>
					<select id="selectLogin" class="select-login input_default form-control" v-model="unidadeSelect" :class="classes" :disabled="disabledUnidade" @change="selectUnidade">
						<option v-show="isAdmin" :value="{tenancyName:'master',id:0}">MASTER</option>
						<option :value="item" v-for="(item,index) in unidadesFiltradas" :key="index">{{item.tenancyName}}</option>
					</select>
					<span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
				</ValidationProvider>
				<div class="col-md-12 mb-2 mt-3" id="orderKeyCont">
        <label class="label_default mb-0">
          Digite sua
          <strong>Senha</strong>
        </label>
      </div>
      <ValidationProvider
        v-slot="{ errors, ariaMsg, classes }"
        rules="required|min:5"
        name="Senha"
        tag="div"
        :bails="false"
        class="mb-3"
        :disabled="disabled"
      >
        <div style="margin-bottom:10px;" id="msg" class="d-none">
          <span style="font-size: 14px;" class="alerta"
            >O Caps-Lock está ativado!</span
          >
        </div>

        <input
          :class="classes"
          type="password"
          class="px-2 py-2 input_default form-control"
          placeholder="****"
          v-model="senha"
          :disabled="disabledInputPassword"
          id="txtSenha"
        />

        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
				<div>
					<button
						type="submit"
						class="btn_default px-2 py-2"
						:disabled="disableSpinnerButtonLogin"
						id="buttonSubmitLogin"
					>
						<span
							class="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
							v-show="disableSpinnerButtonLogin"
						></span>
						{{ !disableSpinnerButtonLogin ? "Entrar" : "" }}
					</button>
				</div>
				<div class="col-md-12 my-1">
					<p class="esqueci_senha">
						Esqueceu a senha?
						<a href="#" @click.prevent="esqueciSenha()">Clique aqui.</a>
					</p>
				</div>
				<div class="routeCont">
					<router-link :to="'/unidadeCpf'" class="outra-conta" v-if="this.sizeWindow < 768">
							{{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
							<strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
					</router-link>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
import load from '@/components/utils/_Load'
import { faThList } from '@fortawesome/free-solid-svg-icons'
import { TheMask } from 'vue-the-mask'

export default {
  components: {
    load,
    TheMask
  },
  data () {
    return {
      disabled: false,
      disabledCpf: false,
      disableSpinnerButtonCpf: false,
      disableSpinnerButtonLogin: false,
      disabledUnidade: true,
      disabledInputPassword: true,
      CPF: '',
      senha: '',
      mensagem: '',
      unidades: [],
      unidadesFiltradas: [],
      unidadeSelect: '',
      isAdmin: false,
      loader: false,
      sizeWindow: 0
    }
  },
  mounted () {
    this.sizeWindow = $(window).width()
    $('#txtSenha').keyup(function (e) {
      var key = e.charCode || e.keyCode
      var tamanho = this.value.length
      var ultimoCaracter = this.value.substring(tamanho - 1)

      if (tamanho === 0) {
        $('#msg').addClass('d-none')
        return false
      }

      if (key === 20 && tamanho !== 0) {
        $('#msg').toggleClass('d-none')
        return false
      }

      // Verifica se é maiúsculo, e se não é shift
      if (
        ultimoCaracter.toUpperCase() === ultimoCaracter &&
        ultimoCaracter.toLowerCase() !== ultimoCaracter
      ) {
        $('#msg').removeClass('d-none')
      }
    })
  },
  methods: {
    getUserWithCpf() {
      this.unidades = [];
      this.$store
      .dispatch('getTenants', this.idsUnidades)
      .then(resolve => {
        this.unidades = resolve
        this.loader = false
      })
      .catch(reject => {});

      this.disableSpinnerButtonCpf = true;
      this.isAdmin = false;
      this.unidadeSelect = '';
      this.$store.dispatch('cpfForLogin', this.CPF).then(resolve => {
        if (!resolve.erro) {
          this.disableSpinnerButtonCpf = false;
          this.disabledUnidade = false;
          this.$parent.gratientAnimator(
            'linear-gradient(135deg, #e0a638 0%, #f38235 100%)'
          );

          let idsUnidades = []

          resolve.result.forEach(user => {
            if (user.profile === 1) {
              idsUnidades.push(0);
              this.isAdmin = true;
            } else {
              idsUnidades.push(user.tenant.id);
            }
          });
          this.unidadesFiltradas = this.unidades.filter(unidade => idsUnidades.includes(unidade.id));
          this.updateUnidades = true;
        } else {
          this.$notify({
            group: 'erros',
            type: 'error',
            text: `<i class="icon ion-close-circled"></i>${resolve.message}`
          });
          this.disableSpinnerButtonCpf = false;
        }
      });
    },
    selectUnidade () {
      this.$store.commit('set_tenant', this.unidadeSelect)

      if (faThList.user === undefined && this.$store.state.login.usersForLogin.length > 1) {
        let user = this.$store.state.login.usersForLogin.filter(user => user.tenant.id === this.unidadeSelect.id)

        if (user) {
          this.$store.commit('set_user', user[0])
        }
      }

      this.disabledInputPassword = false
      console.log(this.unidadeSelect)
    },
    esqueciSenha () {
      this.$loading()
      this.$store.dispatch('gerateReset').then(resolve => {
        this.$loading.close()
        if (resolve) {
          this.$router.push('/esqueci-a-senha')
        }
      })
    },
    loginStep () {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.disableSpinnerButtonLogin = true
          this.$store.dispatch('gerateToken', this.senha).then(resolve => {
            if (!resolve.error) {
              this.disableSpinnerButtonLogin = false

              this.$parent.gratientAnimator(
                'linear-gradient(to right, #e0a638 0%,#f38235 100%)'
              )
              this.$store.dispatch('getServices')
              this.$store.dispatch('getSuporte')
              this.$store.commit('SET_TIME_START', new Date().getTime())
              if (this.$store.getters.getUser.profile != 3) {
                this.$router.push('/')
              } else {
                this.$router.push('/abrir-caixa')
              }
            } else {
              this.toast(`${resolve.message}`, this.$toast.error)
              this.disableSpinnerButtonLogin = false
            }
          })
        }
      })
    }
  },
  watch: {
    unidadeSelect () {}
  },
  computed: {
    user () {
      return this.$store.state.login.userLogin
    }
  }
}
</script>

<style scoped>
#loginInputContainer {
	display: flex;
	width: 100%;
	padding-right: 0 !important;
  padding-left: 0 !important;
}
#inputLogin {
	border-radius: 7px 0 0 7px !important;
}
#buttonCpfLogin {
	height: 100%;
	border-radius: 0 7px 7px 0 !important;
}
.image_container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.routeCont{
  margin-top: 17%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.routeCont a{
  color: #A5A6AD;
}
h4 {
  margin-bottom: 0.1rem !important;
  color: #2474b2;
  font-size: 16px;
}
h5 {
  color: #f38235;
  font-size: 14px;
}
.name {
  margin-left: 16px;
  text-align: left;
}
.image_div {
  overflow: hidden;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border: 4px solid #2474b2;
}
.icon_block {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.49);
}

.image_div .img_user {
  object-fit: cover;
  height: auto;
  min-height: 100%;
  width: 100%;
  object-position: top;
}
.esqueci_senha {
  color: #868686 !important;
}
.esqueci_senha a {
  color: #2474b2 !important;
  font-weight: 700;
}
#msg {
  margin-bottom: 1px;
}
.alerta {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  color: #f38235;
}
.select-login {
	padding: .5rem 0 .5rem 0 !important;
  min-height: 35px;
}
@media screen and (max-width:767px){
	.input_default{
		width: 100%;
		text-align: center;
		background-color: #ffffff !important;
		font-weight: 500 !important;
		border: 1px solid #98C4E6;
		border-radius: 7px !important;
		height: calc(2.5em + 0.75rem + 2px);
	}
	#buttonSubmitLogin{
		margin-top: 5%;
		height: 40px;
		width: 100%;
		border-radius: 10px;
		font-weight: 500;
		border: 1px solid #2474b2;
		background-color: #2474b2;
		color: #ffff;
		box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
		transition: all 0.2s linear;
		font-size: 18px;
	}
  #orderKeyCont{
    margin-top: 2.5rem!important;
  }
  label{
    font-size: 16px;
  }
  .esqueci_senha{
    font-size: 16px;
  }
  .esqueci_senha a{
    font-size: 14px;
  }
  .outra-conta{
    font-size: 16px;
  }
}
</style>
